<template>
  <div v-if="showFrame" class="iframe-wrapper">
    <iframe class="iframe" :src="loginUserData.loginUrl" />
    <div class="iframe-btns">
      <AntButton class="btn" type="primary" @click="onClickOpenFrame">
        <template #icon>
          <BackIcon />
        </template>
        Back
      </AntButton>
      <AntButton class="btn" type="primary" @click="onClickReload">
        <template #icon>
          <ReloadIcon />
        </template>
        Re-load
      </AntButton>
    </div>
  </div>

  <AntRow v-else>
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <a class="link" target="_blank" :href="loginUserData.loginUrl">{{ loginUserData.loginUrl }}</a>

      <AntRow justify="center">
        <AntButton class="btn" type="primary" target="_blank" :href="loginUserData.loginUrl">
          <template #icon>
            <NewWindowIcon :rotate="90" />
          </template>
          Open in New Tab
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickOpenFrame">
          <template #icon>
            <FrameIcon />
          </template>
          Open in Frame
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickReload">
          <template #icon>
            <ReloadIcon />
          </template>
          Re-generate
        </AntButton>
      </AntRow>

    </AntCol>
  </AntRow>

</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import {
  Row as AntRow,
  Col as AntCol,
  Button as AntButton,
} from 'ant-design-vue'

import NewWindowIcon from '@ant-design/icons-vue/lib/icons/SelectOutlined'
import FrameIcon from '@ant-design/icons-vue/lib/icons/ExpandOutlined'
import ReloadIcon from '@ant-design/icons-vue/lib/icons/ReloadOutlined'
import BackIcon from '@ant-design/icons-vue/lib/icons/LeftOutlined'

import { AWC_ENV_LIST } from '@/constants/index'

export default {
  name: 'EnterGame',
  components: {
    AntRow,
    AntCol,
    AntButton,
    NewWindowIcon,
    FrameIcon,
    ReloadIcon,
    BackIcon,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    requestResponseData: Object,
  },
  emits: ['changeLoginUserData'],
  setup(props, { emit }) {
    const router = useRouter()
    if (!props.loginUserData.userId) router.push('/tssw/login')

    const showFrame = ref(false)
    const requestResponseData = reactive(props.requestResponseData)

    const onClickOpenFrame = () => showFrame.value = !showFrame.value

    const onClickReload = async () => {
      const { awcEnv: tsswAwcEnv, loginUserData } = props
      const [awcEnv] = tsswAwcEnv.split('_') // AWCA_PR => AWCA, AWS03_PR => AWS03

      const URL = `${AWC_ENV_LIST[awcEnv].domain}/wallet/${loginUserData.loginFunction}`
      const { data: { data: awcData } } = await axios.post(`/proxy/${URL}`, loginUserData.loginRequestData)

      if (awcData) {
        requestResponseData.url = URL
        requestResponseData.request = JSON.stringify(loginUserData.loginRequestData)
        requestResponseData.response = JSON.stringify(awcData)

        if (awcData.status === '0000') {
          emit('changeLoginUserData', { loginUrl: awcData.url })
        }
      }
    }

    return {
      showFrame,
      onClickOpenFrame,
      onClickReload,
    }
  },
}
</script>

<style scoped>
.iframe-wrapper {
  position: relative;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.btn {
  margin: 28px 8px 0px;
}

.iframe-btns {
  position: absolute;
  bottom: 0;
  right: 0;
}

.iframe-btns .btn {
  margin: 0 0 0 8px;
}

.link {
  word-break: break-word;
}
</style>
